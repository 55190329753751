<template>
  <div v-if="!loading">
    <Card :tabs="items" :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template v-if="!!$route.params.id" #actions>
        <router-link :to="{ name: 'user-edit', params: { id: lead?.user_id } }">
          <v-btn class="primary mr-4" color="darken-1" v-if="lead.user_id">
            Kullanıcıya Git
          </v-btn>
        </router-link>
        <v-btn
          v-permission="{
            permissions: ['leads-update'],
          }"
          @click="editMode = !editMode"
        >
          <span v-if="editMode">Görüntüle</span>
          <span v-if="!editMode">Düzenle</span>
        </v-btn>
      </template>
      <template v-if="Number($route.params.id)" #extension.before>
        <router-link
          v-if="Number($route.params.id) != leads[0].id"
          :to="{
            name: 'lead-show',
            params: {
              id: Number($route.params.id) - 1,
            },
            query: {
              editable: $route.query?.editable,
            },
          }"
        >
          <v-btn class="primary" elevation="0">Önceki Lead</v-btn>
        </router-link>
      </template>
      <template v-if="Number($route.params.id)" #extension.after>
        <router-link
          v-if="Number($route.params.id) != leads[leads?.length - 1].id"
          :to="{
            name: 'lead-edit',
            params: {
              id: Number($route.params.id) + 1,
            },
            query: {
              editable: $route.query?.editable,
            },
          }"
        >
          <v-btn class="primary" elevation="0">Sonraki Lead</v-btn>
        </router-link>
      </template>
      <template #item.informations>
        <v-card flat class="pa-4">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-container>
                  <h2 class="mb-4">Bilgiler</h2>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="lead.name"
                        label="Ad Soyad"
                        hide-details="auto"
                        :rules="[
                          (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
                        ]"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="lead.email"
                        hide-details="auto"
                        label="E-Posta"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <SMPhoneSelectbox
                        :phone="lead.phone"
                        @update:phone="(phone) => (lead.phone = phone)"
                      ></SMPhoneSelectbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <SMAgentSelectbox
                        :agent_id="lead.agent_id"
                        @update:agent_id="
                          (agent_id) => (lead.agent_id = agent_id)
                        "
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <SMChannelSelectbox
                        :required="true"
                        :value="lead.channel_id"
                        @update:value="
                          (channel_id) => (lead.channel_id = channel_id)
                        "
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <SMCampaignSelectboxVue
                        :required="true"
                        :value="lead.campaign_id"
                        @update:value="
                          (campaign_id) => (lead.campaign_id = campaign_id)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-row class="mt-3">
                  <v-col cols="12">
                    <SMButtonGroup
                      @reset="reset"
                      @save="save"
                      @saveAndClose="saveAndClose"
                      :isFormValid="isFormValid"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col v-if="$route.params.id" cols="12" md="6">
                <Notes
                  :noteable_type="'App\\Models\\Lead'"
                  :noteable_id="Number($route.params.id)"
                />
              </v-col> -->
            </v-row>
          </v-form>
        </v-card>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_LEAD,
  CREATE_LEAD,
  UPDATE_LEAD,
  FETCH_LEADS,
} from "@/core/services/store/leads.module";
import { isEmptyObject } from "jquery";

// import Notes from "@/view/content/notes/Notes.vue";
import Card from "@/view/content/components/Card";

import SMCampaignSelectboxVue from "@/view/content/siliconmade/SM-Campaign-Selectbox.vue";
import SMChannelSelectbox from "@/view/content/siliconmade/SM-Channel-Selectbox.vue";
import SMButtonGroup from "@/view/content/siliconmade/SM-Button-Group.vue";
import SMAgentSelectbox from "@/view/content/siliconmade/SM-Agent-Selectbox.vue";
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";

export default {
  components: {
    Card,
    SMCampaignSelectboxVue,
    SMChannelSelectbox,
    // Notes,
    SMButtonGroup,
    SMAgentSelectbox,
    SMPhoneSelectbox,
  },
  data() {
    return {
      editMode: false,
      lead: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
      items: [{ value: "informations", text: "Bilgiler" }],
      tab: null,
    };
  },
  mounted() {
    if (isEmptyObject(this.leads)) this.fetchLeads();

    if (this.$route.params.id) {
      this.getLead(Number(this.$route.params.id))
        .then(() => {
          this.lead = Object.assign({}, this.getCurrentLead);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_LEAD, CREATE_LEAD, UPDATE_LEAD, FETCH_LEADS]),
    handleEditChange() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.$router.replace({ query: { editable: String(this.editMode) } });
      } else {
        this.$router.replace({ query: {} });
      }
    },
    async save(replaceRoute = true) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        this.$root.$emit("stopSpinning");
        return;
      }
      if (this.$route.params.id) {
        try {
          const lead = JSON.parse(JSON.stringify(this.lead));
          const data = {
            ...lead,
            id: Number(this.$route.params.id),
          };
          await this.updateLead(data);
          this.$toast.success("Lead Guncellendi.", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;

          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const lead = await this.createLead(this.lead);
          if (replaceRoute) {
            this.$router.push({
              name: "lead-edit",
              params: { id: lead.id },
              query: { editable: String(this.editMode) },
            });
          }
          this.$toast.success("Yeni Lead Yaratıldı.", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;

          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
      this.$root.$emit("stopSpinning");
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "lead-list" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.lead = Object.assign({}, this.getCurrentLead);
      } else {
        this.lead = {};
      }
      this.$router.push({ name: "lead-list" });
    },
  },
  computed: {
    ...mapGetters(["getCurrentLead", "leads"]),
    hasId() {
      return this.$route.params.id;
    },
    title() {
      if (this.$route.params.id) {
        return "Lead Düzenle";
      }
      return "Yeni Lead Oluştur";
    },
  },
};
</script>

<style>
.user-management--disabled {
  pointer-events: none;
  opacity: 0.6;
  filter: grayscale(100%);
}
</style>
