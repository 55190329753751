<template>
  <v-select
    @input="selectCampaign"
    :items="campaigns"
    item-value="id"
    item-text="name"
    hide-details="auto"
    v-model="selectedCampaign"
    :loading="loading"
    label="Kampanya"
    :required="required"
    :rules="rules"
    :multiple="multiple"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        close
        small
        @click:close="selectedCampaign.splice(index, 1)"
        v-if="multiple"
      >
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-else>{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SM-Campaigns-Selectbox",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCampaign: this.value,
      loading: true,
      campaigns: [],
      rules: [(v) => !!v || "Kampanya seçimi zorunludur."],
    };
  },
  async mounted() {
    this.campaigns = await this.getCampaigns();
  },
  methods: {
    selectCampaign() {
      if (this.multiple) {
        this.$emit(
          "update:value",
          this.campaigns
            .filter((campaign) => this.selectedCampaign.includes(campaign.id))
            .map((c) => {
              return {
                id: c.id,
                name: c.name,
              };
            })
        );
        return;
      }
      this.$emit("update:value", this.selectedCampaign);
    },
    getCampaigns() {
      this.$apiService
        .get("campaigns")
        .then(({ data }) => {
          this.campaigns = data;
        })
        .catch(() => {
          this.campaigns = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.multiple && this.selectedCampaign != value) {
          this.selectedCampaign = value;
          return;
        }
      },
    },
  },
});
</script>
