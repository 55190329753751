var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('Card',{attrs:{"tabs":_vm.items,"title":_vm.title,"disabled":!_vm.editMode},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1)]},proxy:true},(!!_vm.$route.params.id)?{key:"actions",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'user-edit', params: { id: _vm.lead?.user_id } }}},[(_vm.lead.user_id)?_c('v-btn',{staticClass:"primary mr-4",attrs:{"color":"darken-1"}},[_vm._v(" Kullanıcıya Git ")]):_vm._e()],1),_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['leads-update'],
        }),expression:"{\n          permissions: ['leads-update'],\n        }"}],on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[(_vm.editMode)?_c('span',[_vm._v("Görüntüle")]):_vm._e(),(!_vm.editMode)?_c('span',[_vm._v("Düzenle")]):_vm._e()])]},proxy:true}:null,(Number(_vm.$route.params.id))?{key:"extension.before",fn:function(){return [(Number(_vm.$route.params.id) != _vm.leads[0].id)?_c('router-link',{attrs:{"to":{
          name: 'lead-show',
          params: {
            id: Number(_vm.$route.params.id) - 1,
          },
          query: {
            editable: _vm.$route.query?.editable,
          },
        }}},[_c('v-btn',{staticClass:"primary",attrs:{"elevation":"0"}},[_vm._v("Önceki Lead")])],1):_vm._e()]},proxy:true}:null,(Number(_vm.$route.params.id))?{key:"extension.after",fn:function(){return [(Number(_vm.$route.params.id) != _vm.leads[_vm.leads?.length - 1].id)?_c('router-link',{attrs:{"to":{
          name: 'lead-edit',
          params: {
            id: Number(_vm.$route.params.id) + 1,
          },
          query: {
            editable: _vm.$route.query?.editable,
          },
        }}},[_c('v-btn',{staticClass:"primary",attrs:{"elevation":"0"}},[_vm._v("Sonraki Lead")])],1):_vm._e()]},proxy:true}:null,{key:"item.informations",fn:function(){return [_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',[_c('h2',{staticClass:"mb-4"},[_vm._v("Bilgiler")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Ad Soyad","hide-details":"auto","rules":[
                        (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
                      ],"required":""},model:{value:(_vm.lead.name),callback:function ($$v) {_vm.$set(_vm.lead, "name", $$v)},expression:"lead.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-Posta"},model:{value:(_vm.lead.email),callback:function ($$v) {_vm.$set(_vm.lead, "email", $$v)},expression:"lead.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SMPhoneSelectbox',{attrs:{"phone":_vm.lead.phone},on:{"update:phone":(phone) => (_vm.lead.phone = phone)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('SMAgentSelectbox',{attrs:{"agent_id":_vm.lead.agent_id},on:{"update:agent_id":(agent_id) => (_vm.lead.agent_id = agent_id)}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('SMChannelSelectbox',{attrs:{"required":true,"value":_vm.lead.channel_id},on:{"update:value":(channel_id) => (_vm.lead.channel_id = channel_id)}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('SMCampaignSelectboxVue',{attrs:{"required":true,"value":_vm.lead.campaign_id},on:{"update:value":(campaign_id) => (_vm.lead.campaign_id = campaign_id)}})],1)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('SMButtonGroup',{attrs:{"isFormValid":_vm.isFormValid},on:{"reset":_vm.reset,"save":_vm.save,"saveAndClose":_vm.saveAndClose}})],1)],1)],1)],1)],1)],1)]},proxy:true}],null,true)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }