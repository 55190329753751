<template>
  <v-select
    :items="agents"
    item-value="id"
    item-text="name"
    hide-details="auto"
    v-model="selectedAgent"
    :multiple="multiple"
    :loading="loading"
    clearable
    label="Agent"
    @input="selectAgent"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        close
        small
        @click:close="selectedAgent.splice(index, 1)"
        v-if="multiple"
      >
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-else>{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
import { defineComponent } from "vue";
import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "SM-Agent-Selectbox",
  props: {
    agent_id: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      agents: [],
      selectedAgent: this.agent_id,
    };
  },
  async mounted() {
    await this.getAgents();
    this.loading = false;
  },
  methods: {
    async getAgents() {
      try {
        const { data } = await AxiosInstance.get("roles/agents");
        const mappedAgents = data.map((agent) => ({
          id: agent.id,
          name: agent.name,
        }));
        localStorage.setItem("agents", JSON.stringify(mappedAgents));
        this.agents = mappedAgents;
      } catch (error) {
        this.$toast.error("Agentlar alınamadı.", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    selectAgent() {
      if (this.multiple) {
        this.$emit(
          "update:agent_id",
          this.agents.filter((agent) => this.selectedAgent.includes(agent.id))
        );
        return;
      }
      this.$emit("update:agent_id", this.selectedAgent);
    },
  },
  watch: {
    agent_id: {
      immediate: true,
      handler(value) {
        if (this.multiple && this.selectedAgent != value) {
          this.selectedAgent = value;
          return;
        }
      },
    },
  },
});
</script>
