<template>
  <v-select
    @input="selectChannel"
    :items="channels"
    item-value="id"
    item-text="name"
    hide-details="auto"
    v-model="selectedChannel"
    :loading="loading"
    label="Kanal"
    :required="required"
    :multiple="multiple"
    :rules="rules"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        close
        small
        @click:close="selectedChannel.splice(index, 1)"
        v-if="multiple"
      >
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-else>{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SM-Channels-Selectbox",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedChannel: this.value,
      loading: true,
      channels: [],
      rules: [(v) => !!v || "Kanal seçimi zorunludur."],
    };
  },
  async mounted() {
    this.channels = await this.getChannels();
  },
  methods: {
    selectChannel() {
      if (this.multiple) {
        this.$emit(
          "update:value",
          this.channels
            .filter((channel) => this.selectedChannel.includes(channel.id))
            .map((c) => {
              return {
                id: c.id,
                name: c.name,
              };
            })
        );
        return;
      }
      this.$emit("update:value", this.selectedChannel);
    },
    getChannels() {
      this.$apiService
        .get("channels")
        .then(({ data }) => {
          this.channels = data;
        })
        .catch(() => {
          this.channels = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.multiple && this.selectedChannel != value) {
          this.selectedChannel = value;
          return;
        }
      },
    },
  },
});
</script>
